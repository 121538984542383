<!-- =========================================================================================
	File Name: TableMiscellaneous.vue
	Description: Combine filter,sorter, pagination etc.
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Version: 1.1
	Author: Pixinvent
	Author URL: hhttp://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <receipt forwho="s" :orderid="orderHeaderId" :watermark="watermark"></receipt>
    <!-- <div class="pagebreak"></div> -->
    <receipt forwho="c" :orderid="orderHeaderId" :watermark="watermark"></receipt>
  </div>
</template>


<script>
import Receipt from "./Receipt2.vue";
export default {
  data() {
    return {
      orderHeaderId: "",
      watermark: false
    };
  },
  created() {
    this.orderHeaderId = this.$route.params.orderid;
    var wm = this.$route.params.watermark;

    if (wm === "f") {
      this.watermark = false;
    } else {
      this.watermark = true;
    }
  },
  mounted() {
    setTimeout(() => {
      window.print();
    }, 3000);
  },
  components: {
    Receipt
  }
};
</script>
<style>
.page {
  width: 210mm;
  min-height: 291mm;
  padding: 4mm 4mm 2mm;
  margin: 0 auto;
  border: 1px #d3d3d3 solid;
  /* border-radius: 5px; */
  background: white;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
@page {
  size: A4;
  margin: 0;
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }

  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
</style>
